import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useAuth } from 'src/providers/AuthProvider'

import { Page } from 'src/components/layouts/Page'

import { dashboardPath } from 'src/config/paths'

const Login = () => {
  const { loginWithCustomRedirect } = useAuth()
  const router = useRouter()
  const { returnTo } = router.query as { returnTo?: string }

  useEffect(() => {
    loginWithCustomRedirect(returnTo || dashboardPath())
  }, [loginWithCustomRedirect, returnTo])

  return <Page title='Login'>{null}</Page>
}

export default Login
